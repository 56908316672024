import { Box, Icon, P1, Stack, Tooltip, useConfig } from "@mailbrew/uikit";
import { sourcesData } from "data/sourcesData";
import { motion } from "framer-motion";
import useDarkModeListener from "hooks/useDarkModeListener";
import { useEffect, useMemo, useState } from "react";
import tinycolor from "tinycolor2";
import { BetaBadge } from "./PatronBetaBadge";

const AddSourceBox = ({ title: providedTitle, sourceType, onClick, info: providedInfo, small, checked, disabled }) => {
  const source = sourcesData[sourceType] ?? {};
  let name = providedTitle ?? source.name;
  let { shortName, additionalInfo, color, beta, glyph: SourceIcon } = source;

  additionalInfo = providedInfo ?? additionalInfo;

  const sourceColor = useSourceColorAdjustedForDarkMode(sourceType);

  const bgColor = useMemo(() => tinycolor(sourceColor).darken(10).setAlpha(0.05).toString(), [sourceColor]);

  return (
    <Box
      onClick={() => !disabled && onClick && onClick()}
      bg={bgColor}
      px={1}
      pt={2.5}
      pb={1.5}
      radius={3}
      style={{
        position: "relative",
        cursor: onClick ? "pointer" : "auto",
      }}
    >
      {checked && <IconChecked />}
      {additionalInfo && !checked && <SourceInfo content={additionalInfo} />}
      {beta && <BetaBadge size={0.7} style={{ position: "absolute", top: "7px", right: "7px" }} />}
      <Stack vertical align="center" gap={0.5}>
        <Box
          style={{
            background: color,
            width: "28px",
            height: "28px",
            borderRadius: "28px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SourceIcon style={{ width: "24px", height: "24px" }} color="white" />
        </Box>
        <P1 fontSize={small ? "13px" : "14.5px"} align="center" noWrap color={sourceColor} weight="500">
          {providedTitle ? name ?? shortName : shortName ?? name}
        </P1>
      </Stack>
    </Box>
  );
};

const SourceInfo = ({ content }) => {
  const config = useConfig();
  return (
    <div style={{ position: "absolute", top: "6px", right: "6px" }}>
      <Tooltip title={content} innerStyle={{ textAlign: "left" }} maxWidth={240} position="top">
        <Icon color={config.colors.c5} name="info" />
      </Tooltip>
    </div>
  );
};

const IconChecked = () => {
  const config = useConfig();
  return (
    <motion.div
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      style={{ position: "absolute", top: "5px", right: "5px" }}
    >
      <Icon size={20} color={config.colors.success} name="checkmarkCircle" />
    </motion.div>
  );
};

export function useSourceColorAdjustedForDarkMode(id) {
  const sourceColor = sourcesData[id]?.color;
  const [sourceColorOverride, setSourceColorOverride] = useState(sourceColor);

  const { isDark } = useDarkModeListener({
    onChange: (isDark) => {
      tweakColorForDarkMode(sourceColor, isDark, setSourceColorOverride);
    },
  });

  useEffect(() => {
    tweakColorForDarkMode(sourceColor, isDark, setSourceColorOverride);
  }, [isDark, sourceColor]);

  return sourceColorOverride;
}

function tweakColorForDarkMode(color, isDark, setSourceColorOverride) {
  if (isDark && tinycolor(color).getLuminance() < 0.1) {
    setSourceColorOverride("white");
  } else if (isDark && tinycolor(color).getLuminance() < 0.3) {
    setSourceColorOverride(tinycolor(color).brighten(30).toString());
  } else {
    setSourceColorOverride(color);
  }
}

export default AddSourceBox;
