import { notif } from "@mailbrew/uikit";
import { useEffect } from "react";

export default function useShowNotificationForApiError(error, fallbackMessage) {
  useEffect(() => {
    if (!error) return;

    if (error?.response?.data?.detail) {
      notif.error(error.response.data.detail);
    } else {
      notif.error(fallbackMessage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, fallbackMessage]);
}
