/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { HStack, P1, P2, SubtleCard } from "@mailbrew/uikit";
import ExternalLink from "components/ExternalLink";

const PatronBetaBadge = ({ sourceName = "" }) => {
  return (
    <SubtleCard position="relative">
      <HStack mb={2}>
        <BetaBadge />
        <P1 weight="600">Patron Beta</P1>
      </HStack>
      <P2>
        As a Patron, you can try {sourceName ? "the " + sourceName : "this"} source in advance. Beware of bugs, and{" "}
        <ExternalLink
          href={`mailto:support@mailbrew.com?subject=${sourceName ? sourceName + "%20" : ""}Source%20Feedback`}
        >
          send feedback
        </ExternalLink>
        .
      </P2>
    </SubtleCard>
  );
};

export const BetaBadge = ({ size = 1, ...otherProps }) => {
  return (
    <div
      css={css`
        display: inline-block;
        background: hsl(50, 100%, 60%);
        padding: ${size * 3}px ${size * 9}px;
        border-radius: ${size * 8}px;
        font-size: ${size * 16}px;
        color: hsl(30, 70%, 10%);
        font-weight: 700;
      `}
      {...otherProps}
    >
      BETA
    </div>
  );
};

export default PatronBetaBadge;
