import api from "dependencies/api";
import { useCallback, useState } from "react";
import notifApiError from "utils/notifApiError";

interface IIssueNavigationReturnValue {
  issue: IIssue;
  hasPrevious: boolean;
  hasNext: boolean;
  loadPrevious: () => void;
  loadNext: () => void;
}

interface IBrew {
  latest_issue: IIssue;
}

interface IIssue {
  id: number;
  serial_number: number;
}

interface IIssueNavigationOptions {
  onIssueChange?: (issue: IIssue) => void;
}

export default function useIssueNavigation(
  brew: IBrew,
  currentIssue: IIssue,
  options?: IIssueNavigationOptions
): IIssueNavigationReturnValue {
  const [issue, setIssue] = useState(currentIssue ?? brew.latest_issue);

  const hasPrevious = issue.serial_number > 1;
  const hasNext = brew.latest_issue.id > issue.id;

  const loadAdjacentIssue = useCallback(
    async (direction) => {
      try {
        const url = direction === 1 ? `/issues/${issue.id}/next/` : `/issues/${issue.id}/prev/`;
        const res = await api.get(url);
        setIssue(res.data);
        options?.onIssueChange?.(res.data);
      } catch (err) {
        notifApiError(err);
      }
    },
    [issue.id, options]
  );

  const loadNext = useCallback(() => {
    loadAdjacentIssue(1);
  }, [loadAdjacentIssue]);

  const loadPrevious = useCallback(() => {
    loadAdjacentIssue(-1);
  }, [loadAdjacentIssue]);

  return { issue, hasNext, hasPrevious, loadPrevious, loadNext };
}
