import { Box, Button, H1, Icon, P2, Stack, useConfig } from "@mailbrew/uikit";
import useGenerateBrew from "hooks/useGenerateBrew";
import useIssueNavigation from "hooks/useIssueNavigation";
import { useState } from "react";
import { useSelector } from "react-redux";
import { brewSyncingSelector } from "reducers/newslettersReducer";
import urls from "urls";
import createShareURL from "utils/createShareURL";
import kFormatter from "utils/kFormatter";
import BrewManageOptionsDropdown from "../BrewManageOptionsDropdown";
import { UnstyledCopyButton } from "../CopyButton";
import BrewInlineTitleEditor from "../editor/BrewInlineTitleEditor";
import { BrewPublishModal } from "../PublishBrewButton";
import { ScheduleEditor } from "../ScheduleEditor";
import UnstyledLink from "../UnstyledLink";

export default function UserPrivateBrewHeader({ brew, issue, onStartEditMode, onIssueChange, hideMoreMenu, children }) {
  const [publishModalShown, setPublishModalShown] = useState(false);
  const [isNewsletterPublic, setIsNewsletterPublic] = useState(brew.public);

  const { startGeneration, isGenerating } = useGenerateBrew(brew, () => {
    window.location.reload();
  });

  const hasIssues = !!brew.latest_issue;

  return (
    <BrewSection mb={6}>
      <H1 align="center">{brew.title}</H1>
      <BrewHeaderDetailsWrapper>
        {hasIssues && <IssueBacklogNavigator brew={brew} currentIssue={issue} onIssueChange={onIssueChange} />}
        <span style={{ cursor: "pointer", color: "currentColor", ...selectNoneStyle }} onClick={onStartEditMode}>
          Edit
        </span>
        <Bullet />
        <BrewHeaderShareButton
          brew={brew}
          onClick={() => setPublishModalShown(true)}
          isNewsletterPublic={isNewsletterPublic}
        />
        {!hideMoreMenu && (
          <Stack noWrap gap={1} align="center">
            <Bullet />
            <BrewManageOptionsDropdown
              brew={brew}
              forceIsSelf
              handleGenerateNewIssue={!isGenerating && startGeneration}
            >
              More
            </BrewManageOptionsDropdown>
          </Stack>
        )}
      </BrewHeaderDetailsWrapper>
      <BrewPublishModal
        isNewsletterPublic={isNewsletterPublic}
        setIsNewsletterPublic={setIsNewsletterPublic}
        newsletter={brew}
        show={publishModalShown}
        setShow={setPublishModalShown}
      />
      {children}
    </BrewSection>
  );
}

const Bullet = () => {
  return <span> • </span>;
};

export const BrewHeaderDetailsWrapper = ({ children }) => {
  return (
    <P2 as="div" noWrap style={selectNoneStyle}>
      <Stack noWrap gap={1} align="center">
        {children}
      </Stack>
    </P2>
  );
};

export const IssueBacklogNavigator = ({ brew, currentIssue, onIssueChange }) => {
  const { issue, hasNext, hasPrevious, loadNext, loadPrevious } = useIssueNavigation(brew, currentIssue, {
    onIssueChange,
  });

  const issueLink = !hasNext
    ? urls.viewNewsletter(brew.share_url)
    : urls.viewNewsletterIssue(brew.share_url, issue.serial_number);

  return (
    <>
      <BrewHeaderNavigateIssueButton enabled={hasPrevious} icon="chevronLeft" onClick={loadPrevious} mr={0.5} />
      <UnstyledLink href={issueLink}>{`Issue #${issue.serial_number}`}</UnstyledLink>
      <BrewHeaderNavigateIssueButton enabled={hasNext} icon="chevronRight" onClick={loadNext} ml={0.5} />
    </>
  );
};

export const BrewHeaderShareButton = ({ brew, onClick, isNewsletterPublic }) => {
  return (
    <>
      <span
        onClick={onClick}
        style={{ cursor: "pointer", marginRight: isNewsletterPublic ? "3px" : 0, ...selectNoneStyle }}
      >
        {brew.subscribers_count
          ? kFormatter(brew.subscribers_count ?? 0) + " subs"
          : isNewsletterPublic
          ? "Public"
          : "Private"}
      </span>
      {isNewsletterPublic && (
        <>
          (<UnstyledCopyButton copyLabel="share link" copyText={createShareURL(brew)} />)
        </>
      )}
    </>
  );
};

export function PrivateBrewHeaderEdit({ onEndEditMode }) {
  const syncing = useSelector(brewSyncingSelector);

  return (
    <BrewSection mb={6}>
      <BrewInlineTitleEditor />
      <Stack mt={2} align="center">
        <Button variant={["pill"]} icon="checkmark" onClick={onEndEditMode} disabled={syncing}>
          Done editing
        </Button>
      </Stack>
      <Stack mt={3} align="center">
        <ScheduleEditor />
      </Stack>
    </BrewSection>
  );
}

export const BrewSection = (props) => {
  const padding = 16;
  return <Box px={padding + "px"} w={676 + padding * 2 + "px"} maxW="100%" mx="auto" {...props} />;
};

export const BrewHeaderNavigateIssueButton = ({ enabled, icon, onClick, ...otherProps }) => {
  const config = useConfig();
  return (
    <Icon
      name={icon}
      color={enabled ? config.colors.c3 : config.colors.c5}
      style={{ cursor: enabled ? "pointer" : "default" }}
      size={16}
      onClick={enabled ? onClick : () => {}}
      {...otherProps}
    />
  );
};

const selectNoneStyle = { userSelect: "none" };
