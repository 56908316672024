import { H1, SmallText, Stack, useConfig, useDetectBrowser } from "@mailbrew/uikit";
import useInputValidator from "hooks/useInputValidator";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { currentNewsletterSelector, updateNewsletterField } from "reducers/newslettersReducer";
import { useDebouncedCallback } from "use-debounce";
import * as Yup from "yup";

const BrewInlineTitleEditor = () => {
  const config = useConfig();
  const { hasTouch } = useDetectBrowser();
  const dispatch = useDispatch();
  const newsletter = useSelector(currentNewsletterSelector);

  const [debouncedUpdateTitle] = useDebouncedCallback(
    (title) => {
      dispatch(updateNewsletterField("title", title));
    },
    500,
    {
      leading: true,
    }
  );

  const [title, setTitle, titleError] = useInputValidator(
    newsletter.title,
    Yup.string().required("Please enter a title"),
    (title) => {
      if (newsletter.untitled) dispatch(updateNewsletterField("untitled", false));
      debouncedUpdateTitle(title);
    }
  );

  return (
    <Stack vertical align="center" gap={0}>
      <H1
        color={config.colors.c1}
        style={{
          width: "100%",
          background: "transparent",
          textAlign: "center",
          WebkitAppearance: "none",
          outline: "none",
          padding: 0,
          border: "none",
          color: newsletter.untitled ? config.colors.c4 : config.colors.c1,
        }}
      >
        <TextareaAutosize
          name="title"
          autoFocus={!hasTouch && newsletter.untitled}
          autoComplete="off"
          value={!newsletter.untitled ? title : "New brew"}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.target.blur();
            }
          }}
          onFocus={(e) => newsletter.untitled && e.target.select()}
          style={{
            fontFamily: "inherit",
            border: "none",
            WebkitAppearance: "none",
            lineHeight: "inherit",
            background: "transparent",
            color: "inherit",
            fontWeight: "inherit",
            fontSize: "inherit",
            outline: "none",
            resize: "none",
            width: "100%",
            position: "relative",
            textAlign: "center",
            padding: 0,
            margin: 0,
          }}
          minRows={1}
          className="data-hj-allow"
        />
      </H1>

      {titleError && <SmallText mb={2}>{titleError}</SmallText>}
    </Stack>
  );
};

export default BrewInlineTitleEditor;
