import Link from "next/link";

const UnstyledLink = ({ style, children, ...props }) => {
  return (
    <Link {...props}>
      <a style={{ color: "currentColor", borderBottom: "none", ...style }}>{children}</a>
    </Link>
  );
};

export default UnstyledLink;
